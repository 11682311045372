@import '../../../settings.scss';

.tab {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 1em;
  margin: 2em 0;
  border: 1px solid #eaeaea;
  transition: background-color linear 0.3s;

  &:first-child {
    border-left: 0;
  }

  &:last-child {
    border-right: 0;
  }

  &:hover {
    background-color: white;
    padding-top: 1.5em;
    transition: padding linear 0.2s;
    
  }

  :first-child {
    padding-right: 0.5em;
  }
}

.active-tab {
  background-color: white;
  width: 100%;
  padding-top: 1.5em;
  border-bottom: 1px solid white;

}

.passive-tab {
  background-color: #eaeaea;
  width: 100%;

  .tabName {
    @media (max-width: $mobile-break-point) {
      display: none;
    }
  }

}



