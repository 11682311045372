@import './../../../settings.scss';

.row {
  width: 100%;
  column-gap: 1.5em;
  > * {
    margin: 0.5em 0;
  }
}

.desktop {
  display: grid;
}

.mobile {
  display: grid;
  @media (max-width: $mobile-break-point) {
    display: flex;
    flex-direction: column;
  }
}