.payment {
  display: flex;
  padding-top: 0.5em;
  margin-bottom: 1em;
  align-items: center;
  
  img {
    padding-left: 1.5em;
    max-height: 2.5em;
  }
}

.redirect {
  margin-bottom: 0;
}