.label {
  display: flex;
  cursor: pointer;
  align-items: center;

  input {
    margin: 0;
    display: none;
  }

  .radio {
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  .checkbox {
    height: 1em;
    width: 1em;
    border: 1px solid black;
    border-radius: 4px;
    margin-right: 1em;
    background-color: white;
  
    path {
      fill: white;
      stroke: white;
    }  
  }
}

.check:checked + .checkbox {
  background-color: black;  
}