.title {
  border: 2px solid lightgray;
  background-color: white;
  margin: 2em 1em;
  padding: 1em;
  width: 15em;
  font-weight: 600;
  color: #444;
}

.primary {
  background-color: black;
  border: 2px solid black;
  color: white;
}

.edit {
  background-color: white;
  cursor: pointer;
}