$mobile: 499.9px;
$desktop: 500px;

.notification-container {
  position: fixed;

  height: 0;
  width: 300px;
  padding: 0;
  margin: 0;
  z-index: 100;

  @media (max-width: $mobile) {
    left: 30px;
    right: 30px;
    bottom: 70px;
    display: flex;
    flex-direction: column-reverse;
    width: calc(100% - 60px);
  }
  @media (min-width: $desktop) {
    top: 70px;
    right: 70px;
    max-width: calc(100vw - 140px);
  }

}

.notification {
  background-color: white;
  position: relative;
  list-style: none;
  padding: 1em;
  box-sizing: border-box;
  border: 1px solid black;
  width: 100%;
  box-shadow: 2px 2px 3px 0px #00000071;

  .actions {
    display: flex;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    margin-top: 1em;
    div {
      width: 100%;
    }
    button {
      width: 100%;
      height: auto;
      padding: 0.25em 1em;
      margin: 0;
    }
  }

  @media (max-width: $mobile) {
    bottom: -100vh;
    right: 0;
    transition: bottom .4s ease, right .4s ease, max-height .1s ease, margin-top .4s ease;
    max-height: 0;
    margin-top: 0;
    &.fadeIn {
      bottom: 0;
      max-height: 100vh;
      margin-top: 1em;
    }

    &.fadeOut {
      right: -100vw;
      max-height: 0;
      margin-top: 0;
    }
  }
  @media (min-width: $desktop) {
    right: -100vw;
    transition: right .4s ease, top .2s ease, max-height .1s ease, max-top .1s ease;
    width: 100%;
    top: 0;
    max-height: 0;
    margin-top: 1em;
  
    &.fadeIn {
      right: 0vw;
      max-height: 100vh;
    }
  
    &.fadeOut {
      top: -100vw;
      max-height: 0;
      margin-top: 0;
    }
  }

  
}