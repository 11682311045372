.list {
  list-style: none;
  padding: 0;

  .address {
    border-bottom: 1px solid #dbdcdd;
    padding-bottom: 1em;
    margin-bottom: 1em;
  }

  li:last-child {
    .radio {
      .address {
        border: 0;
        margin-bottom: 0;
      }
    }
  }
}

.button-wrapper {
  padding: 0 2em 0 calc(2em + 2px);
}

.button {
  width: 100%;
}

.icon {
  width: 2em;
  padding-top: 1em;
  flex-shrink: 0;
}
