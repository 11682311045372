@import '../../../settings.scss';


.footer-links {
  display: flex;
  justify-content: center;
  border-top: 2px solid lightgray;

  .footer-element {
    margin: 0.5em;
    font-size: 0.9em;
    cursor: pointer;
  }
}

.credits {
  text-align: center;
  font-size: 0.8em;
}