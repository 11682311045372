@import '../../../settings.scss';

.bar {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 40em;
  margin: auto;
  padding: 0em;

  li {
    list-style: none;
  }

  > :last-child {
    display: none;
  }
}