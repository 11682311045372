
.totals {
  list-style: none;
  margin-top: -0.05em;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #999999;

  .total {

    border-bottom: 1px solid lightgray;
    margin-left: -2.5em;

    .li-content {
      display: flex;
      justify-content: space-between;
      margin: 0.5em 0;
    }
  }

  .total:last-of-type {
    border: 0;
    font-weight: 900;
    font-weight: bold;
  }
}
