@supports (display: contents){
  .radio-wrapper {
    display: grid;
    grid-template-columns: 0 auto auto 1fr 4fr;
    row-gap: 0.5em;
  }
}

.hr {
  grid-column: 3 / span 5;
  width: 100%;
  border: 1px solid #dbdcdd;
  border-top: 0;

  &:last-of-type {
    display: none;
  }
}