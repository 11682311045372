.column {
  margin-left: 1em;
  border-right: 2px solid lightgray;

  &:last-of-type {
    border-right: 0px;
  }
  
}

