.method {
  align-items: center;
  gap: 1em; 
  width: 95%;
  margin-right: 2em;
  display: contents;
  border-bottom: 1px solid #999999;

  &:last-child {
    border: 0;
  }

  .method-name {
    padding-right: 1em;
    align-content: center;
  }

  .method-image {
    align-self: center;
    justify-self: center;
    height: 2em;
  }
}
