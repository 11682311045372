.button {
  width: 100%;
  margin: 2em 0;
}

.error {
  color: #ba143f;
  margin: 0;
  margin-bottom: 2em;
  font-size: 1.1em;
}