@import '../../../settings.scss';


.footer-navi {
  margin-top: 2em;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
}

.icon-link {
  display: flex;
  align-items: center;
  gap: 0.4em;
}