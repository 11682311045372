@import './settings.scss';

@font-face {
  font-family: "Futura PT";
  src: url('./assets/fonts/futura.woff') format('woff'),
       url('./assets/fonts/futura.woff2') format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: "Futura PT";
  src: url('./assets/fonts/futura-bold.woff') format('woff'),
       url('./assets/fonts/futura-bold.woff2') format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: "Schnyder S";
  src: url('./assets/fonts/SchnyderS-Demi-Web.woff') format('woff'),
       url('./assets/fonts/SchnyderS-Demi-Web.woff2') format('woff2'),
       url('./assets/fonts/SchnyderS-Demi-Web.ttf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: "Futura PT", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  overflow-y: scroll;
  background-color: $color-background;
  
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

* {
  /* box-sizing: border-box; */
  /* font-weight: 400; */

}