

.bar-element {
  a {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5em 0;
    text-decoration: none;
    opacity: 0.25;

    svg {
      padding: 0.3em;
      width: 0.5em;
      height: 25px;
      width: 25px;
    }
  }
  &.disabled a {
    cursor: not-allowed;
  }
  &.active {
    a {
      opacity: 1;
    }
  }
}



.spacer {
  border-bottom: 1px solid black;
  width: 10%;
  height: 1em;
  margin: 2em auto;
}