@import '../../../settings.scss';


.box {
  background-color: white;
  padding: 1.7em 1.5em;
    .box-children {
      display: flex;
    }
}

