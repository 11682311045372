.validation-messages {
  list-style: none;
  margin: 0.25em 0;
  padding: 0;
}
.validation-message {
  margin-top: 0.25em;
  margin-left: 0.75em;
  color: #ba143f;
  font-size: small;
}