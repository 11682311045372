.label {
  display: flex;
  cursor: pointer;
  line-height: 0.5em;

  input {
    margin: 0;
  }

  .radio {
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  .checkbox {
    height: 1em;
    width: 1em;
    border: 1px solid black;
    border-radius: 4px;
    margin-top: 0.5em;
    margin-right: 1em;
    background-color: white;
  
    path {
      fill: white;
      stroke: white;
    }  
  }
}

.radio:checked + .checkbox {
    background-color: black;  
}




