.shipping {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  margin-bottom: 1em;
  
  span {
    &:first-of-type {
      margin-top: 0.9em;
    }
  }
}