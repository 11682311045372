@import '../../../settings.scss';

.identity {
  background-color: white;
  box-shadow: lightgray 0px 8px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  @media (max-width: $mobile-break-point) {
    flex-direction: column-reverse;
  }

  .claim, .spacer {
    width: 30%;
    text-transform: uppercase;
    font-size: 0.5em;
    visibility: hidden;
    @media (max-width: $mobile-break-point) {
      text-align: center;
      display: none;
    }
  }

  .logo {
    width: 100%;
    text-align: center;
    img {
      width: 200px;
    }
  }


}