@import '../../settings.scss';


.box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto ;
  grid-template-areas: 
  'header header'
  'items addresses'
  'items totals';

  @media (max-width: $mobile-break-point) {
    grid-template-columns: 1fr;
    grid-template-areas: 
    'header'
    'addresses'
    'items'
    'totals';
  }
}


.items {
  grid-area: items;
  overflow: auto;
  position: relative;
  margin-right: 0.5em;

  ul {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0 1em;

    @media (max-width: $mobile-break-point) {
      position: relative;
      margin: 0;
    }

    li {
      border-bottom: 1px solid lightgray;
    }
  }
  @media (max-width: $mobile-break-point) {
    margin: 0;
  }
}

.order-button {
  margin-top: 2em;
  width: 100%;
  &.paypal {
    margin: 2em auto;
    max-width: 300px;
  }
}

.header {
  grid-area: header;
  padding-bottom: 0;
  margin-bottom: 1em;

  .heading {
    margin-top: -0.5em;
    margin: 0;
    padding: 0;
  }

  @media (max-width: $mobile-break-point) {
    margin: 0;
  }
}

.addresses {
  grid-area: addresses;
  padding: 0 1em;
  margin-left: 0.5em;
  @media (max-width: $mobile-break-point) {
    margin: 0;
    padding: 0 1.5em;
  }
}

.totals {
  grid-area: totals;
  margin-left: 0.5em;
  padding: 1em;
  @media (max-width: $mobile-break-point) {
    margin: 0;
  }
}
