.animated-input {
  display: inline-block;
  width: 100%;
  position: relative;
  min-width: 5em;

  span {
    color: gray;
    display: block;
    position: absolute;
    top: 0;
    transition: transform .2s ease;
    box-sizing: border-box;
    margin: .75em;
    transform-origin: left;
    text-wrap: nowrap;
  }

  input {
    width: 100%;
    box-sizing: border-box;
    border-radius: 0;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid transparent;
    border-bottom-color: gray;
    font-family: inherit;
    font-size: inherit;
    padding: .75em;
    transition: border-color .5s ease;

    &::placeholder {
      color: transparent;
    }
    &:focus, &:not(:placeholder-shown) {
      outline: none;
    }
  }

  input:focus, input:not(:placeholder-shown) {
    background-color: #fafafa;
  }

  input:focus + span.placeholder, input:not(:placeholder-shown) + span.placeholder {
    transform: translateY(-1.3em) scale(80%);
    background-color: white;
  }

  .password-toggle {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}