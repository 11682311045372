.heading {
  border: 0;
  margin-left: 1em;
}

.search {
  background-color: rgb(244, 244, 244);
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  min-height: 3.75em;
  gap: 0.3em;
  padding: 0.5em;
  margin: 0 1em 1em 1em;
  align-items: center;

  input {
    width: 100%;
    grid-area: input;
  }

  button {
    height: 3.25em;
  }

}

@media (max-width: 600px) {
  .search {
    margin: 0;
    grid-template-columns: 3fr 1fr;
  }

  .heading {
    margin-left: 0;
  }

  .location {
    grid-column: 1 / span 1;
  }
  
  .radius {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column: 2 /span 1;
  }
  
  button {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column: 1 /span 2;
  }

}