.finder {
  margin-bottom: 3em;
}

.heading {
  cursor: pointer;
}

.primary {
  padding: 0.5em 0.2em 0.5em 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  border: 0;
  border-top: 1px solid #dbdcdd;
  border-bottom: 1px solid #dbdcdd;
  cursor: pointer;
  .icon {
    height: 1.2em;
    flex-shrink: 0;
  }
}

.secondary {
  width: 100%;
}