.salon {
  border-top: 1px solid lightgray;
  margin: 0 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.heading {
  border: 0;
  order: 0;
}

.select {
  margin-top: 1em;
  order: 1;
}


.salon-info {
  order: 2;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.icons {
  svg {
    padding-right: 0.3em;
    margin-bottom: 1em;
  }
}

.address {
  display: flex;
  flex-direction: column;
}


@media (max-width: 600px) {
  .salon {
    display: block;
    margin: 2em 0;
    padding-top: 1em;
  }
  .address {
    margin-bottom: 0.5em;
  }
  .select {
    width: 100%;
    margin-top: 0;
  }
}