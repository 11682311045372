.link {
  color: black;
  text-decoration-color: lightgray;
  text-decoration-line: underline;
  position: relative;
  display: inline-block;


  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1.5px;
    width: 0;
    height: 1px;
    background-color: black;
    transition: width 0.3s ease-out;
    transform-origin: left;  
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
}