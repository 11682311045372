.item {
  list-style: none;
}
.item a {
  display: flex;
  padding: 1em 0;
  align-items: center;
  gap: 1em;
  text-decoration: none;
  color: black;

  &:last-of-type {
    border: 0;
  }

  p {
    margin: 0;
  }

  img {
    height: 10em;
  }

  div {
    div {
      border: 0;
      h4 {
        margin: 0;
      }
    }

    .infos {
      color: gray;
    }

    .price {
      margin-top: 2em;
      margin-bottom: 0;
      font-weight: 400;
    }

    .smaller {
      font-size: 0.8em;
    }
  }
}
