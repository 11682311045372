.guestform {
  width: 100%;
  display: flex;
  gap: 1em;
  flex-direction: column;

  .button {
    text-align: right;
    button {
      padding: 0 3em;
    }
  }
}