@import './../../../index.scss';

.heading {
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 0 0.2em 0;

  h2 {
    margin: 0 0 1em 0;
  }

  h3 {
    margin: 0;
  }

  h4 {
    margin: 0;
  }

  .icon {
    height: 30px; 
    width: 30px;
  }

  button {
    border: 0px;
    svg {
      height: auto;
      width: 1.2em;
    }
  }
}

.font {
  font-family: 'Schnyder S';
  border-bottom: 0;

}

