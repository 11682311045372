@import './../settings.scss';

.form {
  width: 100%;

  .checkbox {
    margin: 1em 0;
    margin-left: 1.5em;
  }

  .button {
    text-align: right;
    margin-top: 2em;
    button {
      width: 100%;
      text-align: center;
    }
  }
}